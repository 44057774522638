'use strict'

import session from './session'
import accordian from './accordian'
import backToTopBtn from './backToTopBtn'
import form from './form/formParts'
import { scrollToFormTop, sinInputSkipFocus, resetErrorsToHide, submitFormBtn } from './form/formHelpers'

let today = new Date()
let month = today.getMonth() + 1
let day = today.getDate()
let year = today.getFullYear() - 14

const optoutInit = () => {
	var url = window.location.href;
	var x = navigator.cookieEnabled;
	
	if (!url.endsWith("error") || !url.endsWith("noCookie")) {
		if (x == false) {
			window.location="/noCookie";
		}
	}
	if (url.endsWith("selection") || url.endsWith("form") || url.endsWith("confirmation") || url.endsWith("sessionEnded") || url.endsWith("sessionExpired")) { 
		session();
	}
	
  sinInputSkipFocus()
  submitFormBtn()
  accordian()
  $('[data-toggle="datepicker"]').datepicker({
    format: 'mm-dd-yyyy',
    startDate: '01/01/1890',
    endDate: `${month}/${day}/${year}`,
  });
  form.phoneInput.usPhoneFormat({
    format: 'xxx-xxx-xxxx',
  })
  form.birthdate.usPhoneFormat({
    format: 'xx-xx-xxxx',
  })
  form.svgCalendar.on('click', function() {
    form.birthdate.focus()
  })
  backToTopBtn()
}

$(document).ready(function() {
  optoutInit()
})