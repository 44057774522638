// @formatter:off
const form = {
  siteTop: $('html, body'),
  formTop: $('#formTop'),
  sin1: $('#ssn_1'),
  sin2: $('#ssn_2'),
  sin3: $('#ssn_3'),
  birthdate: $('#datepicker'),
  svgCalendar: $('#svgCalendar'),
  birthdateWrap: $('#birthdateWrap'),
  stateWrap: $('#stateInput'),
  stateInput: $('#opt_state'),
  stateDropDown: $('.stateData'),
  zipInput: $('#opt_zip'),
  sinInput: $('.ssn'),
  firstNameWrap: $('#fName'),
  firstNameInput: $('#opt_fname'),
  middleNameWrap: $('#mName'),
  middleNameInput: $('#opt_mname'),
  lastNameWrap: $('#lName'),
  lastNameInput: $('#opt_lname'),
  zipWrap: $('#zipInput'),
  sinWrap: $('#ssnInput'),
  houseNumInput: $('#opt_houseNumber'),
  houseNumWrap: $('#houseNumberInput'),
  addressInput: $('#opt_street'),
  addressWrap: $('#addressInput'),
  aptInput: $('#opt_apt'),
  aptWrap: $('#aptInput'),
  cityInput: $('#opt_city'),
  cityWrap: $('#cityInput'),
  phoneInput: $('#phone'),
  phoneWrap: $('#phoneInput'),
  captchaInput: $('#captchaAnswer'),
  captchaWrap: $('#captchaInput'),
  submitBtn: $('.submitBtn')
}

export default form