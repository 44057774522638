const modal = $('#sessionModal')
const modalContinueBtn = $('#modalContinueBtn')

export function showModal() {
  modal.fadeIn(250)
}

export function hideModal() {
  modal.fadeOut(150)
}

export function SessionModal() {
  modalContinueBtn.on('click', function() {
    hideModal()
  })
}