export default function accordian() {
  // when a tab is clicked
  $('.hideSeekTab').on('click', function() {
    // if the one you clicked is open,
    if ($(this).find('p').hasClass('open') || $(this).find('article').hasClass('open')) {
      // then close it.
      $('.hideSeekTab .open').slideToggle().removeClass('open')
      $('.iconBox').removeClass('closed')
      $(this).removeClass('open')
      // otherwise,
    } else {
      // close all tabs,
      $('.hideSeekTab').removeClass('open')
      $('.hideSeekTab .open').slideToggle().removeClass('open')
      // and open the one you clicked
      $(this).find('p').slideToggle().addClass('open')
      $(this).find('article').slideToggle().addClass('open');
      $('.iconBox').removeClass('closed')
      $(this).find('.iconBox').addClass('closed')
      $(this).addClass('open')
    }
  })
}