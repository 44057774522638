
import form from './formParts'
import { validateForm } from './formValidation'

export function scrollToFormTop() {
  form.siteTop.animate({
    scrollTop: form.formTop.offset().top
  }, 800)
}

export function sinInputSkipFocus() {
  form.sinInput.bind('input', function() {
    let $this = $(this)
    setTimeout(function() {
      if ($this.val().length >= parseInt($this.attr('maxlength'),10)) {
        $this.next('input').focus()
      }
    },0)
  })
}

export function resetErrorsToHide() {
  form.firstNameWrap.removeClass('hasErrors')
  form.firstNameWrap.removeClass('reqErrors')
  form.middleNameWrap.removeClass('hasErrors')
  form.lastNameWrap.removeClass('hasErrors')
  form.lastNameWrap.removeClass('reqErrors')
  form.stateWrap.removeClass('hasErrors')
  form.stateWrap.removeClass('reqErrors')
  form.zipWrap.removeClass('hasErrors')
  form.zipWrap.removeClass('reqErrors')
  form.sinWrap.removeClass('hasErrors')
  form.sinWrap.removeClass('reqErrors')
  form.houseNumWrap.removeClass('hasErrors')
  form.houseNumWrap.removeClass('reqErrors')
  form.addressWrap.removeClass('reqErrors')
  form.addressWrap.removeClass('hasErrors')
  form.aptWrap.removeClass('hasErrors')
  form.cityWrap.removeClass('reqErrors')
  form.cityWrap.removeClass('hasErrors')
  form.phoneWrap.removeClass('hasErrors')
  form.birthdateWrap.removeClass('hasErrors')
  form.birthdateWrap.removeClass('reqErrors');
  form.captchaWrap.removeClass('reqErrors')
  form.captchaWrap.removeClass('hasErrors')
}

export function submitFormBtn() {
  form.submitBtn.on('click', function(event) {
    if (!validateForm()) {
        event.preventDefault()
    }
  })
}