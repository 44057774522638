
import { SessionModal, showModal } from './modal'

export default function session() {
  SessionModal()

  const MAX_IDLE_TIME = 15 //minutes
  const IDLE_TIME_WARNING = 13 //minutes
  let idleTime = 0
  let ii = 0
  let timeDifference = 0
  let timeInterval = 60000 //1 Minute
  let warning = false

  let cookieName = 'OPT_COOKIE'
  let isCookieExist = readCookie(cookieName)
  let idleInterval = setInterval(timerIncrement, timeInterval)

  function Redirect() {
	  var landingUrl = "https://" + window.location.hostname +"/sessionExpired";
	  window.location.href = landingUrl;
  }

  function timerIncrement() {
    idleTime = idleTime + 1
    ii++
    if (idleTime == MAX_IDLE_TIME) { 
      idleTime = 0;
        Redirect()
    } else {
       // display pop up windows after 28 Mins
       if (idleTime == IDLE_TIME_WARNING) {
        	showModal()
            $('#modalContinueBtn').on('click', function() {
                 idleTime = 0
            })
            continueSession()        
       }
     }
   }
  
  function continueSession() {
    warning = false
    readCookie(cookieName)
    startInterval()
  }

  function readCookie(name) {
    let now = new Date
    let timezone = now.getTimezoneOffset()
    let utc_timestamp = now.getTime() + (timezone * 60000)
    let timelength = '' + utc_timestamp
    let nameEQ = name

    let refCha = 'TIMESTAMPID'
    let cStr = document.cookie.split(';')
    for (let i = 0; i < cStr.length; i++) {
      let c = cStr[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) {
        let str = c.substring(nameEQ.length, c.length)
        let n = str.search(refCha)
        let o = str.substr(n + refCha.length, timelength.length)
        timeDifference = utc_timestamp - o
        return timeDifference
      }
    }
    return null
  }

  function setCookie(name, value, mins) {
    console.log('set cookie ran')
    let expires = ''
    let date = new Date()
    if (mins > 0) {
      date.setTime(date.getTime() + (mins * 60 * 1000))
    }
    expires = 'expires = ' + date.toGMTString()
    document.cookie = expires + '; path=/'
  }

  function endSession() {
    console.log('session ended')
    window.close()
  }

   function startInterval() {
     $(this).mousemove(function(e) {
    	 
     });
     $(this).keypress(function(e) {
    	 
     });
   }

  startInterval()
}