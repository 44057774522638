// @formatter:off
import { resetErrorsToHide, scrollToFormTop } from './formHelpers'
import form from './formParts'

export function validateForm() {
  let formValidated = true
  resetErrorsToHide()
  
  if (!formValidated) {
    scrollToFormTop()
  }
  return formValidated
}